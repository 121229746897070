// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-artbook-js": () => import("./../../../src/pages/projects/artbook.js" /* webpackChunkName: "component---src-pages-projects-artbook-js" */),
  "component---src-pages-projects-chatter-js": () => import("./../../../src/pages/projects/chatter.js" /* webpackChunkName: "component---src-pages-projects-chatter-js" */),
  "component---src-pages-projects-guardian-js": () => import("./../../../src/pages/projects/guardian.js" /* webpackChunkName: "component---src-pages-projects-guardian-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-jus-js": () => import("./../../../src/pages/projects/jus.js" /* webpackChunkName: "component---src-pages-projects-jus-js" */),
  "component---src-pages-projects-pictionary-js": () => import("./../../../src/pages/projects/pictionary.js" /* webpackChunkName: "component---src-pages-projects-pictionary-js" */),
  "component---src-pages-projects-shapeworks-js": () => import("./../../../src/pages/projects/shapeworks.js" /* webpackChunkName: "component---src-pages-projects-shapeworks-js" */)
}

